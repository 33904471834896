var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"TRACKING","title-tag":"h3","size":"lg","scrollable":"","hide-footer":"","header-bg-variant":"transparent","modal-class":"custom-modal-amg","header-class":"p-0 custom-modal-header "},on:{"hidden":_vm.close},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"header-modal d-flex justify-content-between"},[_c('h3',{staticClass:"text-white m-0 font-weight-bold"},[_vm._v("Tracking")]),_c('div',[_c('feather-icon',{staticClass:"close-button",attrs:{"icon":"XIcon","size":"22"},on:{"click":_vm.close}})],1)])]},proxy:true}]),model:{value:(_vm.onControl),callback:function ($$v) {_vm.onControl=$$v},expression:"onControl"}},[_c('b-container',[_c('b-row',[_c('b-col',[_c('TitlesModals',{attrs:{"title":_vm.clientName,"label-title":"Client"}})],1),_c('b-col',[_c('TitlesModals',{attrs:{"title":_vm.account,"label-title":"Account"}})],1)],1),(_vm.pType == 4)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"info-container mt-1",class:_vm.isDarkSkin ? 'text-white' : 'text-dark'},[_c('p',{staticClass:"m-0",staticStyle:{"gap":"5px"}},[_vm._v(" Item | "+_vm._s(_vm.pItem.creditor_name)+" ("+_vm._s(_vm.pItem.account_number)+") ")]),_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.pItem.bureau_name),expression:"pItem.bureau_name",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1",attrs:{"src":_vm.pItem.bureau_id == 1
                ? require('@/assets/images/icons/transunion.png')
                : _vm.pItem.bureau_id == 2
                ? require('@/assets/images/icons/experian.png')
                : require('@/assets/images/icons/equifax.png')}})],1)])],1):_vm._e(),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{staticClass:"custom-table",attrs:{"fields":_vm.fields,"no-border-collapse":"","show-empty":"","items":_vm.trackingItems,"busy":_vm.isBusy},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(created_at)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(data.item.created_at))+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('span',{staticClass:"font-weight-bolder",class:data.item.status === 1
                  ? 'text-warning'
                  : data.item.status === 2 || data.item.status === 6
                  ? 'text-danger'
                  : 'text-success'},[_vm._v(" "+_vm._s(data.item.status_name)+" ")]),(data.item.dispute_type && data.item.status === 1)?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(data.item.dispute_type)+" "),(data.item.dispute_type == 'Letter')?_c('tabler-icon',{staticClass:"text-white",attrs:{"icon":"MailIcon","size":"20"}}):_vm._e(),(data.item.dispute_type == 'Direct')?_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"PhoneIcon","size":"20"}}):_vm._e()],1):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }