<template>
  <b-modal
    v-model="onControl"
    title="Edit Account"
    header-class="p-0"
    title-tag="h3"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    scrollable
    size="xmd"
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">Edit Account</h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <b-container>
      <div class="d-flex justify-content-start">
        <div>
          <span v-if="acItemLocal.bureau_id === 1"
            ><span>Trans<span style="color: #4d917a">Union</span></span></span
          >
          <span v-else-if="acItemLocal.bureau_id === 2"
            ><span style="color: #0566b7" class="font-weight-bolder"
              >Experian</span
            ></span
          >
          <span v-else-if="acItemLocal.bureau_id === 3"
            ><span style="color: #822a3a" class="font-weight-bolder"
              >EQUIFAX</span
            ></span
          >
        </div>
      </div>
      <validation-observer ref="form">
        <b-row>
          <b-col
            cols="6"
            v-if="
              dataClient.classification_ncr == null &&
              showAdditionalInputs &&
              dataClient.status_letter !== 'POSITIVE'
            "
          >
            <validation-provider
              v-slot="{ errors }"
              name="Classification ncr"
              rules="required"
            >
              <b-form-group label="Classification NCR">
                <v-select
                  v-model="dataClient.classification_ncr"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classifications"
                  :clearable="false"
                  class="v-select-manual"
                  label="name"
                  :reduce="(option) => option.name"
                  @input="saveNewClassification()"
                />
                <span v-if="errors[0]" class="text-danger"
                  >Classification NCR {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="Account name"
              rules="required"
            >
              <b-form-group label="Account name">
                <vue-autosuggest
                  v-model="acItemLocal.name"
                  :suggestions="optionsToSuggest"
                  :get-suggestion-value="getSuggestionValue"
                  :input-props="{
                    id: 'name-account-general',
                    class: 'form-control',
                    placeholder: 'Select',
                  }"
                  @input="searchOnInput(acItemLocal.name)"
                  @selected="selectHandler"
                >
                  <template v-slot="{ suggestion }">
                    <span class="my-suggestion-item">
                      {{ suggestion.item.name }}
                    </span>
                  </template>
                </vue-autosuggest>
                <span v-if="errors[0]" class="text-danger"
                  >Account name {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="Account number"
              rules="required"
            >
              <b-form-group label="Account #">
                <b-form-input v-model="acItemLocal.account_number" />
                <span v-if="errors[0]" class="text-danger"
                  >Account number {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6" v-if="showAdditionalInputs">
            <validation-provider
              v-slot="{ errors }"
              name="Account status"
              rules="required"
            >
              <b-form-group label="Account status">
                <v-select
                  class="v-select-manual"
                  v-model="acItemLocal.a_status"
                  :options="dataAccountStatus"
                  label="text"
                  :reduce="(option) => option.value"
                  :placeholder="'Select an option'"
                ></v-select>
                <span v-if="errors[0]" class="text-danger"
                  >Account status {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6" v-if="showAdditionalInputs">
            <validation-provider
              v-slot="{ errors }"
              name="Account description"
              :rules="
                Array.isArray(acItemLocal.missing_columns) &&
                acItemLocal.missing_columns.some(
                  (item) => item == 'a_description'
                )
                  ? 'required'
                  : ''
              "
            >
              <b-form-group label="Account description">
                <v-select
                  class="v-select-manual"
                  v-model="acItemLocal.a_desc"
                  :options="dataAccountDescriptions"
                  label="text"
                  :reduce="(option) => option.value"
                  :placeholder="'Select an option'"
                ></v-select>
                <span v-if="errors[0]" class="text-danger"
                  >Account description {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6" v-if="showAdditionalInputs">
            <validation-provider
              v-slot="{ errors }"
              :rules="
                Array.isArray(acItemLocal.missing_columns) &&
                acItemLocal.missing_columns.some((item) => item == 'date')
                  ? 'required|noHtmlTags'
                  : 'noHtmlTags'
              "
            >
              <b-form-group label="Date opened">
                <kendo-datepicker
                  v-model="acItemLocal.date_opened"
                  v-mask="'##/##/####'"
                  :style="
                    isDarkSkin ? 'border-color: rgba(255, 255, 255, 0.4)' : ''
                  "
                  :class="[
                    'w-100 rounded bg-transparent k-picker-custom',
                    { 'text-white': isDarkSkin },
                    errors[0] ? 'is-invalid' : 'valid',
                  ]"
                  :format="'MM/dd/yyyy'"
                  class="form-control"
                  :max="new Date()"
                  placeholder="MM/dd/yyyy"
                />
                <span v-if="errors[0]" class="text-danger"
                  >Date opened {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6" v-if="showAdditionalInputs">
            <validation-provider
              v-slot="{ errors }"
              :rules="
                Array.isArray(acItemLocal.missing_columns) &&
                acItemLocal.missing_columns.some(
                  (item) => item == 'last_payment'
                ) &&
                acItemLocal.date_last_active == null
                  ? 'required|noHtmlTags'
                  : 'noHtmlTags'
              "
            >
              <b-form-group label="Last payment">
                <kendo-datepicker
                  v-model="acItemLocal.last_payment"
                  v-mask="'##/##/####'"
                  :style="
                    isDarkSkin ? 'border-color: rgba(255, 255, 255, 0.4)' : ''
                  "
                  :class="[
                    'w-100 rounded bg-transparent k-picker-custom',
                    { 'text-white': isDarkSkin },
                    errors[0] ? 'border-danger' : '',
                  ]"
                  :format="'MM/dd/yyyy'"
                  class="form-control"
                  :max="new Date()"
                  placeholder="MM/dd/yyyy"
                />
                <span v-if="errors[0]" class="text-danger"
                  >Last payment {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6" v-if="showAdditionalInputs">
            <validation-provider
              v-slot="{ errors }"
              :rules="
                Array.isArray(acItemLocal.missing_columns) &&
                acItemLocal.missing_columns.some(
                  (item) => item == 'date_last_active'
                )
                  ? 'required|noHtmlTags'
                  : 'noHtmlTags'
              "
            >
              <b-form-group label="Date last active">
                <kendo-datepicker
                  v-model="acItemLocal.date_last_active"
                  v-mask="'##/##/####'"
                  :style="
                    isDarkSkin ? 'border-color: rgba(255, 255, 255, 0.4)' : ''
                  "
                  :class="[
                    'w-100 rounded bg-transparent k-picker-custom',
                    { 'text-white': isDarkSkin },
                    errors[0] ? 'is-invalid' : 'valid',
                  ]"
                  :format="'MM/dd/yyyy'"
                  class="form-control"
                  :max="new Date()"
                  placeholder="MM/dd/yyyy"
                />
                <span v-if="errors[0]" class="text-danger"
                  >Date last active {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="Balance"
              rules="required"
            >
              <b-form-group label="Balance">
                <money
                  v-model="acItemLocal.balance"
                  class="form-control"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    precision: 2,
                  }"
                />
                <span v-if="errors[0]" class="text-danger"
                  >Balance {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="Account type"
              rules="required"
            >
              <b-form-group label="Account type">
                <b-form-select
                  v-model="acItemLocal.account_type"
                  :options="accounts"
                  text-field="value"
                  value-field="id"
                />
                <span v-if="errors[0]" class="text-danger"
                  >Account type {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="Payment status"
              :rules="
                showAdditionalInputs &&
                Array.isArray(acItemLocal.missing_columns) &&
                acItemLocal.missing_columns.some(
                  (item) => item == 'last_payment_status'
                )
                  ? 'required'
                  : ''
              "
            >
              <b-form-group label="Payment status">
                <b-form-select
                  v-model="acItemLocal.cr_payment_status_id"
                  :options="paymentsStatus"
                  text-field="name"
                  value-field="id"
                />
                <span v-if="errors[0]" class="text-danger"
                  >Payment status {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Credit Limit">
              <money
                v-model="acItemLocal.credit_limit"
                class="form-control"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  precision: 2,
                }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="Monthly payment"
              :rules="
                showAdditionalInputs &&
                Array.isArray(acItemLocal.missing_columns) &&
                acItemLocal.missing_columns.some(
                  (item) => item == 'monthly_payment'
                )
                  ? 'required|validate-amount'
                  : ''
              "
            >
              <b-form-group label="Monthly Payment">
                <money
                  v-model="acItemLocal.monthly_payment"
                  class="form-control"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    precision: 2,
                  }"
                />
                <span v-if="errors[0]" class="text-danger"
                  >Monthly payment {{ errors[0] }}</span
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Status">
              <b-form-select
                v-model="acItemLocal.status"
                :options="statuses"
                text-field="value"
                value-field="id"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-container>
    <template #modal-footer>
      <b-button
        v-if="
          showAdditionalInputs &&
          dataClient.type_classification == null &&
          dataClient.status_letter != 'POSITIVE'
        "
        variant="success"
        @click="setAnalysisItem"
      >
        Process
      </b-button>
      <button-save v-else @click="saveEdit" />
    </template>
  </b-modal>
</template>

<script>
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";
import { VueAutosuggest } from "vue-autosuggest";
import { mapActions } from "vuex";

export default {
  components: {
    ButtonSave,
    VueAutosuggest,
  },
  props: {
    userId: null,
    itemId: null,
    dataClient: {
      type: Object,
      required: false,
    },
    showAdditionalInputs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onControl: false,
      acItemLocal: [],
      optionsToSuggest: [],
      paymentsStatus: [],
      accounts: [],
      statuses: null,
      isBalanceValid: true,
      dataAccountStatus: [],
      dataAccountDescriptions: [],
      classifications: [],
      onlySaveClassification: false,
      classificationWasSaved: false,
    };
  },
  async created() {
    this.addPreloader();
    await Promise.all([
      this.getAccountItem(),
      this.getAccountsLocal(),
      this.getPaymentsStatus(),
      this.getStatuses(),
      this.getDataAccountStatus(),
      this.getAccountDescriptions(),
      this.getClassifications(),
    ]);
    this.removePreloader();
    this.onControl = true;
  },
  methods: {
    ...mapActions({
      updateRefreshReport: "ReportStore/A_REFRESH_REPORT",
      updateEfectivity: "ReportStore/A_REFRESH_EFECTIVITY",
      A_COUNT_ANALYSIS_CR: "AnalysisCrStore/A_COUNT_ANALYSIS_CR",
    }),
    close() {
      this.$emit("close");
    },
    async searchOnInput(text) {
      try {
        if (text === "" || text === undefined) {
          return;
        }
        const data = await ClientsOptionsServices.searchAccountsCreditors({
          q: text,
        });
        if (data.status === 200) {
          this.optionsToSuggest = [{ data: [...data.data] }];
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },

    async getClassifications() {
      const { data } = await NcrRequestService.getClassificationNcr();
      this.classifications = data;
    },
    async saveNewClassification() {
      const confirm = await this.showConfirmSwal(
        `Are you sure you want to classificate this account as ${this.dataClient.classification_ncr}`,
        "You won't be able to revert this"
      );
      if (!confirm.isConfirmed) {
        this.dataClient.classification_ncr = null;
        this.dataClient.editClassification = false;
        return;
      }
      this.onlySaveClassification = true;
      const params = {
        cr_account_ac_id: this.dataClient.cr_accounts_ac_id,
        classification_ncr: this.dataClient.classification_ncr,
      };
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.saveNewClassification(params);
        if (data.success) {
          this.classificationWasSaved = true;
          this.$emit("reload");
          this.dataClient.classification_ncr_id = this.classifications.find(
            (item) => item.name == this.dataClient.classification_ncr
          ).id;
          this.dataClient.classification = this.dataClient.classification_ncr;
          await this.setAnalysisItem();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async getStatuses() {
      try {
        const data = await ClientsOptionsServices.getStatusLetters();
        if (data.status === 200) {
          this.statuses = data.data;
        }
        this.statuses = this.statuses.filter((item) => item.id !== 1); // not in dispute
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getDataAccountStatus() {
      try {
        const data = await NcrRequestService.getAccountStatuses({
          search: "",
        });
        this.dataAccountStatus = data;
      } catch (ex) {}
    },
    async getAccountDescriptions() {
      try {
        const data = await NcrRequestService.getAccountDescriptions({
          search: "",
        });
        this.dataAccountDescriptions = data;
      } catch (ex) {}
    },
    selectHandler(value) {
      this.acItemLocal.name = value.item.name;
      this.acItemLocal.ca_id = value.item.id;
    },
    async setAnalysisItem() {
      try {
        let response;
        const result = await this.$refs.form.validate();
        if (!result) return;
        if (!this.onlySaveClassification) {
          response = await this.showConfirmSwal(
            "Are you sure to edit and analize this account?",
            "This action will be recorded in the tracking"
          );
        }
        if (response?.isConfirmed || this.onlySaveClassification) {
          const params = {
            cr_account_ac_detail_id: this.dataClient.id,
            cr_accounts_ac_id: this.dataClient.cr_accounts_ac_id,
            ncr_request_id: this.dataClient.ncr_request_id,
            bureau_id: this.dataClient.bureau_id,
            a_name: this.acItemLocal.name ?? "", // INPUT
            a_number: this.acItemLocal.account_number ?? "", // INPUT
            a_type:
              this.accounts.find(
                (item) => item.id === this.acItemLocal.account_type
              )?.value ?? "", // INPUT
            a_status: this.acItemLocal.a_status ?? "",
            a_description: this.acItemLocal.a_desc ?? "",
            monthly: this.acItemLocal.monthly_payment ?? "", // INPUT
            date: this.acItemLocal.date_opened ?? "",
            balance: this.acItemLocal.balance ?? "", // INPUT
            n_months: this.dataClient.n_months ?? "",
            h_credit: this.dataClient.h_credit ?? "",
            c_limit: this.acItemLocal.credit_limit ?? "", // INPUT
            p_status:
              this.paymentsStatus.find(
                (item) => item.id === this.acItemLocal.cr_payment_status_id
              )?.name ?? "", // INPUT
            comments: this.dataClient.comments ?? "",
            last_payment:
              this.acItemLocal.last_payment ??
              this.acItemLocal.date_last_active, // only to analysis
            classification: this.dataClient.classification,
            classification_ncr_id: this.dataClient.classification_ncr_id,
            date_last_active: this.acItemLocal.date_last_active,
            created_by: this.userId,
          };
          const data = await ClientsOptionsServices.setItemAnalysis(params);
          if (data.data.message == "NOT QUALIFIED") {
            this.getAccountItem();
            this.showWarningSwal(
              "The result of the analysis is: NOT QUALIFIED. Verify that the fields are correct."
            );
            return;
          }
          if (
            (data.data.message = "Successful analysis") &&
            !this.onlySaveClassification
          ) {
            await this.saveEdit(true);
            await this.A_COUNT_ANALYSIS_CR({
              ncrRequestId: 0,
              idClient: this.$route.params.idClient,
              typeView: "REMOVE",
              leadId: 0,
              toViewRates: false,
            });
          }
          if (this.onlySaveClassification) {
            this.onlySaveClassification = false;
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        console.log(e);
      }
    },
    async saveEdit(fromProcess = false) {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          let response;
          if (fromProcess) {
            response = {
              isConfirmed: true,
            };
          } else {
            response = await this.showConfirmSwal(
              "Are you sure to edit this account?",
              "This action will be recorded in the tracking"
            );
          }
          if (response.isConfirmed) {
            this.addPreloader();

            try {
              const data = await ClientsOptionsServices.editAccount({
                id: this.acItemLocal.id,
                accountName_id: this.acItemLocal.ca_id,
                accountN: this.acItemLocal.account_number,
                balance: this.acItemLocal.balance.toString(),
                type: this.acItemLocal.account_type,
                iduser: this.userId,
                status: this.acItemLocal.status,
                instruction: null,
                reason: null,
                c_limit: this.acItemLocal.credit_limit.toString(),
                p_status: this.acItemLocal.cr_payment_status_id,
                m_payment: this.acItemLocal.monthly_payment.toString(),
                client_account_id:
                  this.$route.params.idClient ||
                  this.dataClient.client_account_id,
                a_status: this.acItemLocal.a_status,
                a_description: this.acItemLocal.a_desc,
                date: this.acItemLocal.date_opened,
                last_payment: this.acItemLocal.last_payment,
                date_last_active: this.acItemLocal.date_last_active,
                a_type: this.acItemLocal.account_type,
                additional_inputs: this.showAdditionalInputs,
                to_classify: fromProcess,
              });
              if (data.status === 200) {
                this.close();
                this.$emit("reload");
                this.updateRefreshReport(4);
                this.updateEfectivity(true);
                this.showSuccessSwal();
              }
            } catch (error) {
              this.showErrorSwal(error);
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async getAccountItem() {
      try {
        const data = await ClientsOptionsServices.callDelteAccount({
          id: this.itemId,
        });
        if (data.status === 200) {
          this.acItemLocal = data.data[0];
          this.acItemLocal.missing_columns =
            this.acItemLocal.missing_columns ?? [];
          this.acItemLocal.balance = parseFloat(this.acItemLocal.balance);
          this.acItemLocal.credit_limit = this.acItemLocal.credit_limit ?? 0;
          this.acItemLocal.monthly_payment =
            this.acItemLocal.monthly_payment ?? 0;
          this.acItemLocal.a_status =
            this.acItemLocal.a_status == "-" ? null : this.acItemLocal.a_status;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getPaymentsStatus() {
      try {
        const data = await ClientsOptionsServices.getPaymentsStatus({
          id: this.acItemLocal.cr_payment_status_id,
        });
        if (data.status === 200) {
          this.paymentsStatus = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getAccountsLocal() {
      try {
        const data = await ClientsOptionsServices.getStatusAccountsLetter({
          id: this.acItemLocal.account_type,
        });
        if (data.status === 200) {
          this.accounts = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
  },
};
</script>
<style scoped>
.header-modal {
  background-color: var(--primary-color) !important;
  color: white !important;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
</style>
