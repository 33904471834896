<template>
  <b-modal
    v-model="onControl"
    title="TRACKING"
    title-tag="h3"
    size="lg"
    scrollable
    hide-footer
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    @hidden="close"
    header-class="p-0 custom-modal-header "
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">Tracking</h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <b-container>
      <b-row>
        <b-col>
          <TitlesModals :title="clientName" label-title="Client" />
        </b-col>
        <b-col>
          <TitlesModals :title="account" label-title="Account" />
        </b-col>
      </b-row>
      <b-row v-if="pType == 4">
        <b-col cols="12">
          <div
            class="info-container mt-1"
            :class="isDarkSkin ? 'text-white' : 'text-dark'"
          >
            <p style="gap: 5px" class="m-0">
              Item | {{ pItem.creditor_name }} ({{ pItem.account_number }})
            </p>
            <b-img
              class="ml-1"
              v-b-tooltip.hover.top="pItem.bureau_name"
              :src="
                pItem.bureau_id == 1
                  ? require('@/assets/images/icons/transunion.png')
                  : pItem.bureau_id == 2
                  ? require('@/assets/images/icons/experian.png')
                  : require('@/assets/images/icons/equifax.png')
              "
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <b-table
            :fields="fields"
            no-border-collapse
            show-empty
            :items="trackingItems"
            :busy="isBusy"
            class="custom-table"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(created_at)="data">
              <span> {{ data.item.created_at | myGlobalWithHour }} </span>
            </template>
            <template #cell(status)="data">
              <span
                class="font-weight-bolder"
                :class="
                  data.item.status === 1
                    ? 'text-warning'
                    : data.item.status === 2 || data.item.status === 6
                    ? 'text-danger'
                    : 'text-success'
                "
              >
                {{ data.item.status_name }}
              </span>
              <b-badge
                v-if="data.item.dispute_type && data.item.status === 1"
                variant="warning"
                class="ml-1"
              >
                {{ data.item.dispute_type }}
                <tabler-icon
                  v-if="data.item.dispute_type == 'Letter'"
                  icon="MailIcon"
                  class="text-white"
                  size="20"
                />
                <feather-icon
                  v-if="data.item.dispute_type == 'Direct'"
                  icon="PhoneIcon"
                  class="text-white"
                  size="20"
                />
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import TitlesModals from "@/views/commons/utilities/TitlesModals.vue";

export default {
  components: {
    TitlesModals,
  },
  props: {
    clientName: null,
    account: null,
    idLetter: null,
    pItem: null,
    pType: {
      type: Number,
      default: null,
      required: false,
    }
  },
  data() {
    return {
      onControl: false,
      fields: [
        {
          key: "created_at",
          label: "Date",
        },
        {
          key: "created_by",
          label: "User",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "observation",
          label: "Observation",
        },
      ],
      trackingItems: [],
      isBusy: false,
    };
  },
  async created() {
    this.onControl = true;
    await this.getTrackingLetters();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getTrackingLetters() {
      try {
        this.isBusy = true;
        const data = await ClientsOptionsServices.getTrackingLetters({
          id: this.pItem.id,
        });
        if (data.status === 200) {
          this.trackingItems = data.data;
          this.isBusy = false;
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.bg-custom {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child {
  border-top-right-radius: 10px !important;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.info-container {
  display: flex;
  justify-content: start;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
}
</style>
