<template>
  <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
    <p
      style="gap: 5px"
      class="m-0"
    >
      {{ labelTitle }} | {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  name: "TitlesModal",
  props: {
    title: {
      type: String,
      default: ""
    },
    labelTitle: {
      type: String,
      default: "Title"
    }
  }
};
</script>

<style >
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
</style>
